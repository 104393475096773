import { useState, useEffect } from 'react';
import Header from '../components/header';
import logo from '../res/img/logo.svg';

const Landing = () => {
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        // Show the button after 3 seconds
        const timer = setTimeout(() => {
            setShowButton(true);
        }, 1500);

        // Clear the timer if the component unmounts
        return () => clearTimeout(timer);
    }, []);

    return (
        <div className="h-screen flex flex-col">
            <Header isHome={false} className="sticky top-0 z-50" />

            <div className="dark:bg-black flex-1 flex items-center justify-center">
                <div className={`flex flex-col items-center transition-all duration-700 ${showButton ? '-translate-y-10' : ''}`}>
                    <img
                        src={logo}
                        alt="Your Alt Text"
                        className="w-40 h-40 mb-4" // Set size back to 40x40px
                    />
                    <h1 className="text-6xl font-black max-md:text-3xl dark:text-white mb-2">
                        Coursing Through AI
                    </h1>
                    
                    <button 
                        className={`mt-8 px-6 py-2 bg-white text-black font-bold rounded transition-opacity duration-1000 ease-in-out ${showButton ? 'opacity-100' : 'opacity-0'}`}
                    >
                        Get Started
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Landing;
